import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'

import './styles/global.css'

// 引入vue-router
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}



// 使用
Vue.use(VueRouter)

// 引入路由器
import router from './router'

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
    render: h => h(App),
    router: router,

    data: function(){
        return {
            topIndex: '',
        }
    },

}).$mount('#app')
